export const experienceData = [
  {
    id: 1,

    company: "YouthTech Solution LLP",
    jobtitle: "Front-End Developer Intern",
    startYear: "April 2024",
    endYear: "May 2024",
  },
];
